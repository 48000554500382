import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Rating from "@material-ui/lab/Rating"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

import { FcGoogle } from "react-icons/fc"
import { FaFacebookF, FaYelp } from "react-icons/fa"
import BirdEye from "../assets/images/birdeye.svg"

const useStyles = makeStyles(theme => ({
  outerCtr: {
    maxWidth: "90%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  h1: {
    // fontSize: "2.9375rem",
    fontSize: "2rem",
    fontWeight: 400,
    paddingBottom: "1.5625rem",
    paddingTop: "2.75rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.65rem",
    },
  },
  rating: {
    marginBottom: "2.5rem",
    fontSize: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  h6: {
    paddingBottom: "2.75rem",
    fontSize: "1.15rem",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  socialBtn: {
    width: "27rem",
    height: "4.375rem",
    borderColor: "#E4E8F2",
    textTransform: "capitalize",
    fontSize: "1.1rem",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      width: "20rem",
      fontSize: "1rem",
    },
  },
  socialIcon: {
    marginRight: 10,
  },
}))

export default function ReviewSection() {
  const classes = useStyles()

  return (
    <Grid container direction="row" className={classes.outerCtr}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" style={{ paddingTop: "3rem" }}>
          <Grid item container justifyContent="center" xs={12}>
            <Grid item xs={12} md={6}>
              <Grid item xs={12}>
                <Typography variant="h1" align="center" className={classes.h1}>
                  What did you think of your recent experience with us?
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Rating
                name="5-star review"
                value={5}
                readOnly
                className={classes.rating}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item md={6} xl={4}>
                <Typography variant="h2" align="center" className={classes.h6}>
                  Your opinion matters! Please consider leaving us a review on
                  the platform that you prefer. Thank you!
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: "6.25rem" }}>
            <Grid container direction="column" alignItems="center">
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  href="https://www.google.com/search?q=andreatta+waterscapes&rlz=1C1CHBF_enUS904US904&oq=andreatta+waterscapes&aqs=chrome.0.69i59l2j69i64j69i60l3.2388j0j7&sourceid=chrome&ie=UTF-8#lrd=0x54cf7d0fe178fe7d:0x72594151dbc05b5b,3,,,"
                  target="_blank"
                  referrer="noreferrer"
                  className={classes.socialBtn}
                >
                  <FcGoogle size={30} className={classes.socialIcon} />
                  Review on Google
                </Button>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: ".95rem" }}>
                <Button
                  variant="outlined"
                  href="https://www.facebook.com/andreattawaterscapes"
                  target="_top"
                  referrer="noreferrer"
                  className={classes.socialBtn}
                >
                  <FaFacebookF
                    size={30}
                    className={classes.socialIcon}
                    style={{ color: "#4267B2" }}
                  />
                  Review on Facebook
                </Button>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: ".95rem" }}>
                <Button
                  variant="outlined"
                  href="https://www.yelp.com/biz/andreatta-waterscapes-central-point"
                  target="_blank"
                  referrer="noreferrer"
                  className={classes.socialBtn}
                >
                  <FaYelp
                    size={30}
                    className={classes.socialIcon}
                    style={{ color: "#d32323" }}
                  />
                  Review on Yelp
                </Button>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: ".95rem" }}>
                <Button
                  variant="outlined"
                  href="https://reviews.birdeye.com/andreatta-waterscapes-148941557500864"
                  target="_blank"
                  referrer="noreferrer"
                  className={classes.socialBtn}
                >
                  <img
                    src={BirdEye}
                    alt="BirdEye Logo"
                    style={{ maxHeight: 30 }}
                    className={classes.socialIcon}
                  />
                  Review on BirdEye
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
