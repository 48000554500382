import React from "react"
import { Link } from "gatsby"
import AppBar from "@material-ui/core/AppBar"
import { useTheme, makeStyles } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import CssBaseline from "@material-ui/core/CssBaseline"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import MenuIcon from "@material-ui/icons/Menu"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import IconButton from "@material-ui/core/IconButton"
import useMediaQuery from "@material-ui/core/useMediaQuery"
// import logo from "../../assets/images/Andreatta_Waterscapes_logo3.png"
import logo from '../../assets/images/ANDREATTA-LOGO-2021-FINALS-02.png'
import Collapse from "@material-ui/core/Collapse"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"

const useStyles = makeStyles(theme => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: "3em",
    [theme.breakpoints.down("md")]: {
      marginBottom: "7em",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "6.25em",
    },
  },
  logoIconCtr: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  logo: {
    textDecoration: "none",
    zIndex: 1403,
    width: 300,
    [theme.breakpoints.down("md")]: {
      width: 250,
      height: 75,
    },
    [theme.breakpoints.down("xs")]: {
      width: 200,
      height: 65.25,
    },
  },
  drawerIconCtr: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  tabContainer: {
    marginLeft: "auto",
    marginRight: "2em",
  },
  tab: {
    ...theme.typography.tab,
    minWidth: 10,
    marginLeft: "25px",
    // opacity: 1,
    // color: theme.palette.common.muted,
    // color: '#fff',
    color: theme.palette.text.primary,
  },
  appbarClip: {
    //#115293 or #2e74b7
    backgroundColor: "#1c1c1e",
    // backgroundColor: theme.palette.common.muted,
    zIndex: theme.zIndex.modal + 1,
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  drawer: {
    // backgroundColor: theme.palette.common.blue,
    backgroundColor: '#1c1c1e',
  },
  drawerItem: {
    fontFamily: "Work Sans, sans-serif",
    textTransform: "capitalize",
    fontSize: "1rem",
    fontWeight: 400,
    opacity: 0.9,
    padding: ".25em 1em",
    // color: theme.palette.common.muted,
    color: "rgba(255,255,255,1)",
  },
  menu: {
    opacity: 0,
    // backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.muted,
    borderRadius: "0px",
    boxShadow:
      "0px 0px 4px -1px rgba(0,0,0,0.2),0px 4px 5px -2px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  menuTitle: {
    marginBottom: 0,
    marginLeft: ".5rem",
    fontSize: "1.1rem",
    fontWeight: 300,
    textTransform: "uppercase",
  },
  menuItem: {
    // fontWeight: 600,
    // color: theme.palette.common.muted,
    // opacity: 0.9,
    // textTransform: "uppercase",
    // fontSize: ".9rem",
    ...theme.typography.tab,
    color: "rgba(255,255,255,1)",
  },
  newInstallationItem: {
    ...theme.typography.tab,
    color: "rgba(255,255,255,1)",
    "&:hover": {
      cursor: "default",
    },
  },
  subMenuItem: {
    ...theme.typography.tab,
    color: "rgba(255,255,255,1)",
    // fontWeight: 600,
    // color: theme.palette.common.muted,
    // opacity: 0.9,
    // textTransform: "uppercase",
    // fontSize: ".9rem",
    marginTop: 0,
    marginBottom: 0,
  },
  subDrawerItem: {
    fontWeight: 400,
    // color: theme.palette.common.muted,
    color: "rgba(255,255,255,1)",
    opacity: 0.9,
    fontFamily: "Work Sans, sans-serif",
    textTransform: "capitalize",
    fontSize: ".9rem",
    marginTop: "0.15rem",
    marginBottom: "0.15rem",
  },
  nested: {
    paddingLeft: theme.spacing(4),
    "& div": {
      fontSize: ".9rem",
    },
  },
  nestedNewInstallations: {
    paddingLeft: theme.spacing(4),
    "& div": {
      fontSize: ".9rem",
    },
    "&:hover": {
      cursor: "default",
    },
  },
  subNested: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(0.9),
    paddingBottom: theme.spacing(0.9),
    "& div": {
      fontSize: ".9rem",
    },
  },
  subDrawerNested: {
    paddingLeft: theme.spacing(8),
    paddingTop: theme.spacing(0.9),
    paddingBottom: theme.spacing(0.9),
    marginRight: "1rem",
    "& div": {
      fontSize: ".9rem",
    },
  },
  darkArrowsFix: {
    // color: theme.palette.common.muted,
    color: "rgba(255,255,255,1)",
  },
}))

export default function Navbar() {
  const classes = useStyles()
  const theme = useTheme()
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent)

  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [openMenu, setOpenMenu] = React.useState(false)
  const [aboutMenuOpen, setAboutMenuOpen] = React.useState(false)
  const [portfolioMenuOpen, setPortfolioMenuOpen] = React.useState(false)
  const [aboutListOpen, setAboutListOpen] = React.useState(false)
  const [servicesListOpen, setServicesListOpen] = React.useState(false)
  const [portfolioListOpen, setPortfolioListOpen] = React.useState(false)
  const [subListOpen, setSubListOpen] = React.useState(false)
  const [subDrawerListOpen, setSubDrawerListOpen] = React.useState(false)
  // eslint-disable-next-line
  const [tabValue, setTabValue] = React.useState(0)
  const [anchorEl, setAnchorEl] = React.useState(null)


  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    // setOpenMenu(true)
    switch (event.currentTarget.id) {
      case "1": // about
        setAboutMenuOpen(true)
        break
      case "2": // services
        setOpenMenu(true)
        break
      case "3": // portfolio
        setPortfolioMenuOpen(true)
        break
      default:
        break
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpenMenu(false)
    setAboutMenuOpen(false)
    setPortfolioMenuOpen(false)
  }
  const handleAboutClick = () => {
    setAboutListOpen(!aboutListOpen)
    setServicesListOpen(false)
    setPortfolioListOpen(false)
    setSubDrawerListOpen(false)
  }
  const handleSubClick = () => {
    setSubListOpen(!subListOpen)
    setServicesListOpen(false)
    setPortfolioListOpen(false)
  }
  const handleSubDrawerClick = () => {
    setSubDrawerListOpen(!subDrawerListOpen)
    setAboutListOpen(false)
    setPortfolioListOpen(false)
  }
  const handleServicesClick = () => {
    setAboutListOpen(false)
    setPortfolioListOpen(false)
    if (servicesListOpen === true) {
      setServicesListOpen(false)
      setSubDrawerListOpen(false)
    } else {
      setServicesListOpen(true)
    }
  }
  const handlePortfolioClick = () => {
    setPortfolioListOpen(!portfolioListOpen)
    setServicesListOpen(false)
    setAboutListOpen(false)
    setSubDrawerListOpen(false)
  }


  const routes = [
    {
      id: 0,
      text: "Home",
      url: "/",
    },
    {
      id: 1,
      text: "About",
      // url: "/about",
      url: "#",
      ariaOwns: anchorEl ? "about-menu" : undefined,
      ariaPopup: anchorEl ? "true" : undefined,
      mouseOver: e => handleClick(e),
    },
    {
      id: 2,
      text: "Services",
      url: "#",
      ariaOwns: anchorEl ? "simple-menu" : undefined,
      ariaPopup: anchorEl ? "true" : undefined,
      mouseOver: e => handleClick(e),
    },
    {
      id: 3,
      text: "Portfolio",
      url: "#",
      ariaOwns: anchorEl ? "portfolio-menu" : undefined,
      ariaPopup: anchorEl ? "true" : undefined,
      mouseOver: e => handleClick(e),
    },
    {
      id: 4,
      text: "Testimonials",
      url: "/testimonials/",
    },
    // {
    //   id: 3,
    //   text: "Photos",
    //   url: "/photos",
    // },
    // {
    //   id: 4,
    //   text: "Projects",
    //   url: "/projects",
    // },
    // {
    //   id: 4,
    //   text: "Pricing & Financing",
    //   url: "/pricing/",
    // },
    {
      id: 5,
      text: "Resources",
      url: "/resources/",
    },
    {
      id: 6,
      text: "Contact",
      url: "/contact/",
    },
  ]
  const drawerRoutes = [
    {
      id: 0,
      text: "Home",
      url: "/",
    },
    {
      id: 1,
      text: "About",
      url: "/about/",
    },
    {
      id: 2,
      text: "Services",
      url: "/consultations/",
    },
    // {
    //   id: 3,
    //   text: "Maintenance",
    //   url: "/maintenance",
    // },
    {
      id: 4,
      text: "Photos",
      url: "/photos/",
    },
    {
      id: 5,
      text: "Testimonials",
      url: "/testimonials/",
    },
    // {
    //   id: 5,
    //   text: "Projects",
    //   url: "/projects",
    // },
    // {
    //   id: 5,
    //   text: "Pricing & Financing",
    //   url: "/pricing/",
    // },
    {
      id: 6,
      text: "Resources",
      url: "/resources/",
    },
    {
      id: 7,
      text: "Contact",
      url: "/contact/",
    },
  ]
  const newInstallationPages = [
    {
      id: 0,
      text: "Goldfish Ponds & Water Gardens",
      url: "/new-installations/goldfish-ponds/",
    },
    {
      id: 1,
      text: "Koi Ponds",
      url: "/new-installations/koi-ponds/",
    },
    {
      id: 2,
      text: "Pondless Waterfalls",
      url: "/new-installations/pondless-waterfalls/",
    },
    {
      id: 3,
      text: "Waterfalls & Streams",
      url: "/new-installations/waterfalls-streams/",
    },
    {
      id: 4,
      text: "Fountainscapes & Spillways",
      url: "/new-installations/fountainscapes/",
    },
    {
      id: 9,
      text: "Hardscapes",
      url: "/new-installations/hardscapes/",
    },
    {
      id: 5,
      text: "Recreational Ponds",
      url: "/new-installations/recreation-ponds/",
    },
    {
      id: 6,
      text: "Bogs & Wetland Filters",
      url: "/new-installations/bogs-wetland-filters/",
    },
    {
      id: 7,
      text: "Farm & Fire Suppression Ponds",
      url: "/new-installations/fire-suppression-ponds/",
    },
    // {
    //   id: 8,
    //   text: "Fire & Water Features",
    //   url: "/new-installations/fire-water-features/",
    // },
  ]

  const drawer = (
    <>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{
          paper: classes.drawer,
        }}
      >
        <div className={classes.toolbarMargin} />
        <List disablePadding className={classes.darkArrowsFix}>
          {drawerRoutes.map(route => {
            if (route.text === "About") {
              return (
                <React.Fragment key="1about">
                  <ListItem
                    key="1about"
                    divider
                    button
                    onClick={handleAboutClick}
                  >
                    <ListItemText
                      className={classes.drawerItem}
                      disableTypography
                    >
                      About
                    </ListItemText>
                    {aboutListOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={aboutListOpen} timeout="auto">
                    <List component="div" disablePadding>
                      <ListItem
                        divider
                        button
                        className={classes.nested}
                        component={Link}
                        to="/about/"
                        onClick={() => {
                          setOpenDrawer(false)
                        }}
                      >
                        <ListItemText
                          className={classes.drawerItem}
                          disableTypography
                          primary="Company"
                        />
                      </ListItem>
                      <ListItem
                        divider
                        button
                        className={classes.nested}
                        component={Link}
                        to="/in-the-news/"
                        onClick={() => {
                          setOpenDrawer(false)
                        }}
                      >
                        <ListItemText
                          className={classes.drawerItem}
                          disableTypography
                          primary="In The News"
                        />
                      </ListItem>
                      <ListItem
                        divider
                        button
                        className={classes.nested}
                        component={Link}
                        to="/contact/"
                        onClick={() => {
                          setOpenDrawer(false)
                        }}
                      >
                        <ListItemText
                          className={classes.drawerItem}
                          disableTypography
                          primary="Contact"
                        />
                      </ListItem>
                    </List>
                  </Collapse>
                </React.Fragment>
              )
            }
            if (route.text === "Services") {
              return (
                <React.Fragment key="1services">
                  <ListItem
                    key="1services"
                    divider
                    button
                    onClick={handleServicesClick}
                  >
                    <ListItemText
                      className={classes.drawerItem}
                      disableTypography
                    >
                      Services
                    </ListItemText>
                    {servicesListOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={servicesListOpen} timeout="auto">
                    <List component="div" disablePadding>
                      {/* <ListItem
                        component={List}
                        classes={{ root: classes.nested }}
                        button
                        divider
                        onClick={handleSubDrawerClick}
                      >
                        <ListItemText
                          classes={{ root: classes.drawerItem }}
                          disableTypography
                        >
                          New Installations
                        </ListItemText>
                        {subDrawerListOpen ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={subDrawerListOpen} timeout="auto">
                        <List component="div" disablePadding>
                          {newInstallationPages.map(service => (
                            <ListItem
                              key={service.id}
                              button
                              className={classes.subDrawerNested}
                              component={Link}
                              to={service.url}
                              divider
                            >
                              <ListItemText
                                className={classes.subDrawerItem}
                                disableTypography
                                primary={service.text}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Collapse> */}
                      <ListItem
                        divider
                        button
                        className={classes.nestedNewInstallations}
                        component={Typography}
                      >
                        <ListItemText
                          className={classes.drawerItem}
                          disableTypography
                          primary="New Installations"
                        />
                      </ListItem>
                      {newInstallationPages.map(service => (
                        <ListItem
                          key={service.id}
                          button
                          className={classes.subDrawerNested}
                          component={Link}
                          to={service.url}
                          divider
                        >
                          <ListItemText
                            className={classes.subDrawerItem}
                            disableTypography
                            primary={service.text}
                          />
                        </ListItem>
                      ))}
                      <ListItem
                        divider
                        button
                        className={classes.nested}
                        component={Link}
                        to="/repairs-renovations/"
                        onClick={() => {
                          setOpenDrawer(false)
                        }}
                      >
                        <ListItemText
                          className={classes.drawerItem}
                          disableTypography
                          primary="Repairs & Renovations"
                        />
                      </ListItem>
                      <ListItem
                        divider
                        button
                        className={classes.nested}
                        component={Link}
                        to="/maintenance/"
                        onClick={() => {
                          setOpenDrawer(false)
                        }}
                      >
                        <ListItemText
                          className={classes.drawerItem}
                          disableTypography
                          primary="Maintenance"
                        />
                      </ListItem>
                      {/* <ListItem
                        divider
                        button
                        className={classes.nested}
                        component={Link}
                        to="/outdoor-living/"
                        onClick={() => {
                          setOpenDrawer(false)
                        }}
                      >
                        <ListItemText
                          className={classes.drawerItem}
                          disableTypography
                          primary="Outdoor Living"
                        />
                      </ListItem> */}
                    </List>
                  </Collapse>
                </React.Fragment>
              )
            }
            if (route.text === "Photos") {
              return (
                <React.Fragment key="3portfolio">
                  <ListItem
                    key="3portfolio"
                    divider
                    button
                    onClick={handlePortfolioClick}
                  >
                    <ListItemText
                      className={classes.drawerItem}
                      disableTypography
                    >
                      Portfolio
                    </ListItemText>
                    {portfolioListOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={portfolioListOpen} timeout="auto">
                    <List component="div" disablePadding>
                      <ListItem
                        divider
                        button
                        className={classes.nested}
                        component={Link}
                        to="/inspiration-gallery/"
                        onClick={() => {
                          setOpenDrawer(false)
                        }}
                      >
                        <ListItemText
                          className={classes.drawerItem}
                          disableTypography
                          primary="Inspiration Gallery"
                        />
                      </ListItem>
                      <ListItem
                        divider
                        button
                        className={classes.nested}
                        component={Link}
                        to="/projects/"
                        onClick={() => {
                          setOpenDrawer(false)
                        }}
                      >
                        <ListItemText
                          className={classes.drawerItem}
                          disableTypography
                          primary="Project Showcase"
                        />
                      </ListItem>
                      <ListItem
                        divider
                        button
                        className={classes.nested}
                        component={Link}
                        to="/outdoor-living/"
                        onClick={() => {
                          setOpenDrawer(false)
                        }}
                      >
                        <ListItemText
                          className={classes.drawerItem}
                          disableTypography
                          primary="Outdoor Living"
                        />
                      </ListItem>
                    </List>
                  </Collapse>
                </React.Fragment>
              )
            }
            return (
              <ListItem
                divider
                key={route.id}
                button
                component={Link}
                to={route.url}
                onClick={() => {
                  setOpenDrawer(false)
                }}
              >
                <ListItemText
                  className={classes.drawerItem}
                  disableTypography
                  component={Link}
                  to={route.url}
                >
                  {route.text}
                </ListItemText>
              </ListItem>
            )
          })}
        </List>
      </SwipeableDrawer>
      <Container maxWidth="lg">
        <Grid container justifyContent="center">
          <IconButton
            aria-label="navigation menu"
            className={classes.drawerIconCtr}
            onClick={() => setOpenDrawer(!openDrawer)}
            disableRipple
          >
            {/* <MenuIcon style={{ color: "#000" }} /> */}
            <MenuIcon style={{ color: "#d7dadc" }} />
            <Typography
              variant="h6"
              paragraph
              className={classes.menuTitle}
              style={{ color: "#d7dadc" }}
              // style={{ color:"#000" }}
            >
              Menu
            </Typography>
          </IconButton>
        </Grid>
      </Container>
    </>
  )

  const tabs = (
    <>
      <Tabs value={tabValue} className={classes.tabContainer}>
        <Tab
          label="Home"
          aria-label="Home"
          disableRipple
          style={{ display: "none" }}
        />
        {routes.map(route => {
          return (
            <Tab
              className={classes.tab}
              key={route.id}
              id={route.id}
              label={route.text}
              aria-label={route.text}
              aria-owns={route.ariaOwns}
              aria-haspopup={route.ariaPopup}
              onMouseOver={route.mouseOver}
              component={Link}
              to={route.url}
              disableRipple
              // style={{ color: "#000" }}
            />
          )
        })}
      </Tabs>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        classes={{ paper: classes.menu }}
        MenuListProps={{ onMouseLeave: handleClose }}
        elevation={0}
        style={{ zIndex: 1402 }}
        keepMounted
      >
        {/* <MenuItem
          component={List}
          classes={{ root: classes.menuItem }}
          button
          onClick={handleSubClick}
        >
          <ListItemText classes={{ root: classes.menuItem }} disableTypography>
            New Installations
          </ListItemText>
          {subListOpen ? <ExpandLess /> : <ExpandMore />}
        </MenuItem>
        <Collapse in={subListOpen} timeout="auto">
          <List component="div" disablePadding>
            {newInstallationPages.map(service => (
              <ListItem
                key={service.id}
                button
                className={classes.subNested}
                component={Link}
                to={service.url}
                divider
              >
                <ListItemText
                  className={classes.subMenuItem}
                  disableTypography
                  primary={service.text}
                />
              </ListItem>
            ))}
          </List>
        </Collapse> */}
        <MenuItem
          component={Typography}
          classes={{ root: classes.newInstallationItem }}
          disableRipple
        >
          New Installations
        </MenuItem>
        {newInstallationPages.map(service => (
          <MenuItem
            key={service.id}
            component={Link}
            to={service.url}
            className={classes.subNested}
            classes={{ root: classes.subMenuItem }}
            onClick={handleClose}
          >
            {service.text}
          </MenuItem>
        ))}
        <MenuItem
          component={Link}
          to="/repairs-renovations/"
          classes={{ root: classes.menuItem }}
          onClick={handleClose}
        >
          Repairs & Renovations
        </MenuItem>
        <MenuItem
          component={Link}
          to="/maintenance/"
          classes={{ root: classes.menuItem }}
          onClick={handleClose}
        >
          Maintenance
        </MenuItem>
        {/* <MenuItem
          component={Link}
          to="/outdoor-living/"
          classes={{ root: classes.menuItem }}
          onClick={handleClose}
        >
          Outdoor Living
        </MenuItem> */}
      </Menu>
      <Menu
        id="about-menu"
        anchorEl={anchorEl}
        open={aboutMenuOpen}
        onClose={handleClose}
        classes={{ paper: classes.menu }}
        MenuListProps={{ onMouseLeave: handleClose }}
        elevation={0}
        style={{ zIndex: 1402 }}
        keepMounted
      >
        <MenuItem
          component={Link}
          to="/about/"
          classes={{ root: classes.menuItem }}
          onClick={handleClose}
        >
          Company
        </MenuItem>
        <MenuItem
          component={Link}
          to="/in-the-news/"
          classes={{ root: classes.menuItem }}
          onClick={handleClose}
        >
          In The News
        </MenuItem>
        <MenuItem
          component={Link}
          to="/contact/"
          classes={{ root: classes.menuItem }}
          onClick={handleClose}
        >
          Contact
        </MenuItem>
      </Menu>
      <Menu
        id="portfolio-menu"
        anchorEl={anchorEl}
        open={portfolioMenuOpen}
        onClose={handleClose}
        classes={{ paper: classes.menu }}
        MenuListProps={{ onMouseLeave: handleClose }}
        elevation={0}
        style={{ zIndex: 1402 }}
        keepMounted
      >
        <MenuItem
          component={Link}
          to="/inspiration-gallery/"
          classes={{ root: classes.menuItem }}
          onClick={handleClose}
        >
          Inspiration Gallery
        </MenuItem>
        <MenuItem
          component={Link}
          to="/projects/"
          classes={{ root: classes.menuItem }}
          onClick={handleClose}
        >
          Project Showcase
        </MenuItem>
        <MenuItem
          component={Link}
          to="/outdoor-living/"
          classes={{ root: classes.menuItem }}
          onClick={handleClose}
        >
          Outdoor Living
        </MenuItem>
      </Menu>
    </>
  )

  return (
    <>
      <CssBaseline />

      <AppBar className={classes.appbarClip}>
        <Toolbar>
          <IconButton
            aria-label="back to home page"
            component={Link}
            to="/"
            disableRipple
            className={classes.logoIconCtr}
          >
            <img
              src={logo}
              alt="Andreatta Waterscapes Logo"
              className={classes.logo}
              // width="200"
              height="90.25"
            />
          </IconButton>
          {/* {matchesMD ? drawer : tabs} */}
          {!matchesMD && tabs}
        </Toolbar>
        {matchesMD && drawer}
      </AppBar>

      <Toolbar />
    </>
  )
}
