import React from "react"

import Layout from "../components/UI/Layout"
import Seo from "../components/Seo"
import Navbar from "../components/UI/Navbar"
import ReviewSection from "../components/ReviewSection"

export default function ReviewPage() {
  return (
    <Layout>
      <Navbar />
      <Seo
        title="Leave us a Review"
        description="Enjoy your recent experience with Andreatta Waterscapes? Please consider leaving a review! Your feedback is appreciated."
      />
      <ReviewSection />
    </Layout>
  )
}
